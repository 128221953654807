import React from "react";
import { RouteComponentProps } from "@reach/router";
import { withAuth, WithAuth } from "./withAuth";
import { withApollo, WithApolloClient } from "react-apollo";
import gql from "graphql-tag";
import { navigate } from "gatsby-link";
import { CircularProgress } from "@material-ui/core";
import { FundOnboarding } from "../../graphql/FundOnboarding";

interface Props extends WithAuth, WithApolloClient<{}>, RouteComponentProps {}

interface State {
  authenticated: boolean | null;
  error: string;
}

class ImplicitCallback extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      authenticated: null,
      error: null
    };
  }

  async componentDidMount() {
    try {
      const { data } = await this.props.client.query<FundOnboarding>({
        query: gql`
          query FundOnboarding {
            fund {
              id
              onboarding
            }
          }
        `,
        fetchPolicy: "network-only"
      });

      const referrerKey = "secureRouterReferrerPath";
      const location = JSON.parse(
        localStorage.getItem(referrerKey) || '{ "pathname": "/" }'
      );
      localStorage.removeItem(referrerKey);

      switch (data.fund.onboarding) {
        case "get-started":
          navigate("/get-started");
          break;
        case "first-app":
          navigate("/first-app");
          break;
        case "first-steps":
          navigate("/first-steps");
          break;
        default:
          navigate(location.pathname);
          break;
      }
    } catch (ex) {
      navigate("/sign-in", {
        state: {
          message: ex.toString(),
          error: true
        }
      });
    }
  }

  render() {
    if (this.state.authenticated === null) {
      return <CircularProgress color="primary" />;
    }

    return <p>{this.state.error}</p>;
  }
}

export default withApollo(withAuth(ImplicitCallback));
