import React from "react";
import { Router } from "@reach/router";
import ImplicitCallback from "../../components/auth/ImplicitCallback";

const Client = () => (
  <React.Fragment>
    <Router>
      <ImplicitCallback path="/implicit/callback/" />
    </Router>
  </React.Fragment>
);

export default Client;
